import React from 'react'

function ThankYou() {
  return (
    <>
      <h1 className='thanksPage'>Thank You For Your Interest in Our Work !</h1>
    </>
  )
}

export default ThankYou
