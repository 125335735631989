import React from 'react'

function notFound() {
    return (
        <>
            <div className="notFound">
                <h1> 404 PAGE NOT FOUND  </h1>
                <h4>no page with this URL.</h4>
            </div>
        </>
    );
}

export default notFound;
